.greetings {
    .image {
        object-fit: cover;
        height: 200px;
        border-radius: 5px;
    }

    .headerTitle {
        text-transform: unset;
        font-family: Loffers, Gotham, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
        font-size:40px;
    }

    .button {
        width: 150px !important;
        min-width: 100px;
        min-height: 100px;

        .icon {
            font-size: 30px;
        }
        .text {
            font-weight: 600;
        }
    }
}