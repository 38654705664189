@import '../../styles/config.scss';

.containerDelivery {
  background-color: $neutralQuaternaryAlt;
  padding: 10px 5px;
  border-radius: 5px;
}

.textLabel {
  font-weight: 500;
}

.iconInfo {
  cursor: pointer;
  position: relative;
  top: 2px;
}

.priceDetails {
}

.productImage {
  width: 100px;
  height: 100px;
  border-radius: $border-radius;
}

.item {
  padding-bottom: 20px;

  .index {
    min-width: 25px;
    max-width: 25px;
  }

  .price {
    min-width: 110px;
    max-width: 110px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #bbb;
  }
}