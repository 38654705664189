@import '../../styles/config.scss';

.title {
  font-size: 20px;
  font-weight: 500;
}
.card {
  border-radius: 5px;
  background-color: $neutralPrimaryAlt;
  width: 100%;
  padding: 20px;
  user-select: none;
  cursor: pointer;
  .text {
    color: $white;
    font-weight: 500;
    font-size: 16px;
  }
}
.card:hover,
.selected {
  background-color: $themePrimary !important;
}

.item {
  background-color: $themeLighter;
  padding: 20px;
  border-radius: 5px;
  .title {
    font-weight: 500;
  }
  cursor: pointer;
  user-select: none;
}

.item:hover {
  background-color: $themePrimary;
  .title,
  .subTitle {
    color: $white;
  }
  .icon {
    svg {
      color: $white;
    }
  }
}
