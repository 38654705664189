@import "../../../../styles/config.scss";

.deliveryDetails {
  width: "100%";

  .card {
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    padding: 20px;
    width: 100%;

    .image {
      max-width: 100px;
      width: 100%;
  
      img {
        width: 100px;
        background-color: $white;
        border-radius: $border-radius;
        object-fit: contain;
        height: 75px;
        margin: 0px auto;
      }
    }

    .detailsButton {
      height: 30px;
      min-height: 30px;
      min-width: auto;
      width: auto;
      margin-top: 5px;
    }
  }
}
