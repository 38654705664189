@import "../../../../styles/config.scss";

.flavours {
  .qtyButton {
    height: 28px;
    min-height: 28px;
    width: 28px;
    min-width: 28px;

    &[disabled] {
      background-color: $neutralLight;
    }

    * {
      font-size: 14px;
    }
  }
}

.container {
  width: 100%;

  .card {
    width: 100%;

    .image {
      width: 75px;

      img {
        border-radius: $border-radius;
        max-width: fit-content;
        object-fit: contain;
        height: 75px;
        margin: 0px auto;
      }
    }

    .icon {
      padding: 5px;
      border-radius: $border-radius;
      background-color: $themeLight;
      color: $themePrimary;
    }

    .additionalPrice {
      border-radius: $border-radius;
      background-color: $themePrimary;
      color: $white;
      padding: 5px;
    }
  }
}
