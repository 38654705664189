@import '../../../../styles/config.scss';

.package {
  background-color: $themeLighter;
  padding: 30px;
  border-radius: 5px;
  min-width: 200px;
  min-height: 100px;
  .textTitle {
    font-weight: 500;
    font-size: 16px;
    color: $white;
  }
  .textPrice {
    color: $white;
    font-size: 14px;
  }
  &:hover {
    background-color: $themePrimary;
  }
  user-select: none;
  cursor: pointer;

  .imgPackage {
    max-width: 200px;
  }
}

.container {
  width: 100%;
  flex-wrap: wrap;
  column-gap: 15px;
  
  .card {
    width: calc(33% - 15px);
    border-radius: $border-radius;
    padding: 10px;
    margin-bottom: 20px;

    .image {
      width: 100%;
      border-radius: $border-radius;
      background-color: $themeLighter;

      img {
        width: 150px;
        height: 150px;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: $themeLighter;
    }
  }
}

@media (max-width: 720px) {
  .container {
    justify-content: flex-start;

    .card {
      width: calc(50% - 15px);
    }
  }
}