@import '../../styles/config.scss';

.item {
  padding-bottom: 20px;

  .index {
    min-width: 25px;
    max-width: 25px;
  }

  .productImage {
    width: 100px;
    height: 75px;
    object-fit: cover;
    border-radius: $border-radius;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #bbb;
  }
}