@import '../styles/config.scss';

.container {
  min-height: 100vh;

  .content {
    padding: 20px;
    padding-bottom: 120px;
  }
}

.img {
  object-fit: cover;
  height: 200px;
  border-radius: 5px;
}
