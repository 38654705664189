@import "../../../../styles/config.scss";

.selectDeliveryDate {
  width: "100%";

  .card {
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    padding: 20px;
    width: 100%;

    &.active {
        background-color: $themeLighter;
    }

    &:hover {
      cursor: pointer;
      background-color: $themeLighter;
    }
  }
}
