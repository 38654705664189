@import '../../styles/config.scss';

.container {
  background-color: $themePrimary;
  height: 100px;
  padding: 20px;

  .text {
    color: $white;
    font-size: xx-large;
  }

  .headerTitle {
    text-transform: uppercase;
  }

  .iconNotification {
    position: relative;
    background-color: $white;

    * {
      color: $themePrimary;
    }

    .dot {
      border-radius: 50%;
      position: absolute;
      background: $themeLighter;
      height: 20px;
      width: 20px;
      top: -9px;
      right: -9px;

      .text {
        color: $themePrimary;
        font-size: 14px;
      }
    }
  }
}
