@import "./config.scss";

@font-face {
  font-family: "Gotham";
  src: url(../assets/fonts/Gotham-Book_0.otf) format("opentype");
}

@font-face {
  font-family: "Amersn";
  src: url(../assets/fonts/AMERSN__.ttf) format("truetype");
}

@font-face {
  font-family: "Loffers";
  src: url("../assets/fonts/Loffers.eot"); /* IE9 Compat Modes */
  src: url("../assets/fonts/Loffers.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/Loffers.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/Loffers.ttf") format("truetype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

body {
    background-color: $secondaryPrimary;
}

h1 {
  font-family: Amersn, Gotham, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  margin: 0px;
  text-transform: uppercase;
}

h2, h3, h4, h5, h6 {
  font-family: Gotham, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  margin: 0px;
  font-weight: 400;
  text-transform: uppercase;
}

a {
  color: $themePrimary;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $themeDarker;
  }
}

.clickable:hover {
  cursor: pointer;
}

img {
  max-width: 100%;
}

.lou-content {
  background: $white;
  padding: 20px;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
}

.lou-of-container {
    .lou-of-content {
        max-width: 720px;
        width: 100%;
        margin: 0px auto;
    }
}

// custom class
.nbt-linePivot {
  .ms-Pivot {
    border-bottom: 1px solid $neutralQuaternaryAlt;
  }

  .ms-Pivot-link {
    margin-bottom: -1px;
    padding: 0px 20px;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
}

// update default react trello styles
.kanbanBoard {
  padding: 20px;

  .react-trello-board {
    padding: 0px;
    background: transparent;
    width: 100%;
    height: 100%;

    .new-lane-button {
      margin: 5px;
      width: 250px;
      height: 40px;
    }

    .react-trello-lane.new-lane-form {
      margin: 5px;
      padding: 12px;
    }

    .react-trello-lane {
      background: $neutralLight;
      padding: 0px;
      width: 250px;
      border-radius: 5px;

      .kanban-card {
        padding: 10px;
        background: $white;
        margin-bottom: 12px;
        border-radius: 5px;
        box-shadow: $box-shadow;

        &:hover {
          background: $neutralLighterAlt;
          cursor: pointer;
        }
      }

      .kanban-addCard {
        border-top: 1px solid $neutralLighter;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        &:hover {
          background: $neutralQuaternaryAlt;
          /** {color: $white;}*/
        }
      }

      textarea {
        font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
          -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
          sans-serif;
        height: auto !important;
        padding: 8px 0px;
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        font-weight: 600;
        box-shadow: none;
        margin: 0px;
        box-sizing: border-box;
        border-radius: 0px;
        border: none;
        background: none transparent;
        color: rgb(0, 0, 0);
        width: 100%;
        min-width: 0px;
        text-overflow: ellipsis;
        outline: 0px;
        min-height: 32px;

        &:focus {
          padding: 8px;
          background: $white;
          border: 2px solid $themePrimary;
        }
      }

      & > header {
        padding: 10px;

        & > span {
          width: 100%;
          margin-right: 20px;
        }
      }

      & > div {
        width: 100%;
        margin-top: 0px;

        .smooth-dnd-container {
          padding: 0px 10px;
        }

        & > a {
          padding: 10px;
        }
      }
    }
  }
}

svg {
  position: relative;
  width: 16px;
  height: 16px;
}

// update default fabric ui styles
.ms-DetailsList {
  .ms-DetailsHeader {
    padding: 0px;
  }
}

.ms-Button--icon {
  background: $neutralLighter;
  border-radius: 50%;
  min-width: 40px;

  &:hover {
    background: $neutralLight;
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $neutralTertiaryAlt !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $neutralTertiaryAlt !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $neutralTertiaryAlt !important;
}
