@import '../../styles/config.scss';

.containerImage {
  position: relative;
  .img {
    height: 60px;
    width: 60px;
    border-radius: 5px;
  }
  .startContainer {
    position: absolute;
    right: -10px;
    top: -10px;
    background-color: $themeTertiary;
    height: 26px;
    width: 26px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 100px;
    .star {
      color: $white;
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}
