@import '../../styles/config.scss';

.container {
  background-color: $secondaryPrimary;
  box-shadow: 0 1.6px 20px 1px rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
  position: fixed;
  padding: 20px;
  height: 100px;
  bottom: 0;
  width: 100%;
  left: 0px;
  right: 0px;
}

.btnPrimary {
  padding: 25px;
  border-radius: 5px;
}
