@import "./config.scss";

.linePivot {
    .ms-Pivot {
        border-bottom: 1px solid $neutralQuaternaryAlt;
    }

    .ms-Button {
        margin-bottom: -1px;
    }
}

.ms-DetailsList {
    .ms-DetailsHeader {
        padding-top: 0px;
    }

    .textColumn {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.ms-CommandBar {
    height: 40px;
    padding: 0px;

    .ms-SearchBox, .ms-Button {
        height: 100%;
        margin-right: 8px;
    }
}

.ms-TextField {
    .ms-TextField-fieldGroup {
        outline: 0px;
        min-height: 40px;
        border-radius: $field-border-radius;

        input.ms-TextField-field {
            padding: 4px 8px;
        }

        textarea.ms-TextField-field {
            padding: 6px;
        }

        &::after {
            border-radius: $field-border-radius;
        }
    }

    div.ms-TextField-field {
        line-height: 38px;
    }

    .ms-TextField-prefix {
        border-top-left-radius: $field-border-radius;
        border-bottom-left-radius: $field-border-radius;
    }

    .ms-TextField-suffix {
        border-top-right-radius: $field-border-radius;
        border-bottom-right-radius: $field-border-radius;
    }

    .ms-DatePicker-event--without-label {
        padding: 10px 5px 5px;
    }
}

.ms-Dropdown-container {
    .ms-Dropdown-title {
        height: 40px;
        padding: 4px 6px;
    }

    .ms-Dropdown-caretDownWrapper {
        height: 26px;
        padding: 6px 0px;
    }
}

.ms-Button {
    border-radius: $field-border-radius;
    min-height: 40px;

    &.ms-Facepile-descriptiveOverflowButton {
        width: 32px;
        padding-right: 3px;
        height: 32px;
        min-height: auto;
        background: $neutralTertiary;

        * {color: $white;}
    }
}

.ms-SearchBox {
    border-radius: $field-border-radius;
    min-height: 40px;
    min-width: 225px;

    input.ms-SearchBox-field {
        padding: 2px 6px;
    }

    &.is-active::after {
        border-radius: $field-border-radius;
    }

    .ms-SearchBox-clearButton button {
        height: 30px;
        width: 30px;
        min-height: unset;
        min-width: unset;
        border-radius: 50%;
        margin: 3px;
    }
}

.ms-BasePicker {
    input, .ms-BasePicker-text {
        height: 44px;
    }

    .ms-BasePicker-itemsWrapper > .ms-TagItem {
        height: 38px;
        display: flex;
        align-items: center;
    }

    .ms-PickerPersona-container {
        height: 32px;
        padding: 0px 5px;
    }
    
    .ms-PickerItem-removeButton {
        height: 24px;
        min-height: unset;
    }
}

.ms-MessageBar{
    .ms-MessageBar-icon {
        margin: 14px 0px 14px 12px;
    }

    .ms-MessageBar-text {
        margin: 14px;
    }

    .ms-MessageBar-innerText {
        margin-top: 2px;
    }
}

.ms-Breadcrumb {
    margin-top: 0px;
}

.ms-Dialog {
    .ms-Dialog-main {
        max-width: 480px;
    }
}

.ms-Dialog-actions {
    line-height: unset;
}

.ms-Checkbox-checkbox svg {
    margin: 0px;
}

.ms-Panel {
    .ms-Panel-footerInner {
        background-color: $white;
        border-top: 1px solid $neutralLighter;
    }

    .ms-Panel-commands {
        margin-top: 0px;
    }

    .ms-Panel-navigation {
        background-color: $white;
        border-bottom: 1px solid $neutralLighter;
        padding: 10px 0px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        .ms-Panel-header {
            align-self: center;
        }
    }
}

.blockRadio {
    .ms-ChoiceField {
        margin-bottom: 5px;
        background-color: $grey-light;
        padding: 10px;
        border-radius: $border-radius;
        margin-top: 2px;
    }
}